import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { UserService } from '@app/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class ProfileResolver {

    private profile: any;

    constructor(
        private userService: UserService
    ) { }

    getProfile() {
        return this.profile;
    }

    updateProfile(profile: any) {
        this.profile.id = profile.id;
        this.profile.name = profile.name;
        this.profile.date_of_birth = profile.date_of_birth;
        this.profile.height = profile.height;
        this.profile.right_handed = profile.right_handed;
        this.profile.email = profile.email;
        this.profile.address = profile.address;
        this.profile.city = profile.city;
        this.profile.state = profile.state;
        this.profile.zip_code = profile.zip_code;
        this.profile.country = profile.country;
        this.profile.measurement_system_id = profile.measurement_system_id;
        this.profile.photo = profile.photo;
        this.profile.role = profile.role;
        this.profile.plan_id = profile.plan_id;
        this.profile.account_id = profile.account_id;
        this.profile.phone = profile.phone;
        this.profile.gym = profile.gym;
        this.profile.school_grade_id = profile.school_grade_id;
        this.profile.coaching_experience = profile.coaching_experience;
        this.profile.coaching_level = profile.coaching_level;
    }

    clearProfile() {
        this.profile = null;
    }

    resolve(): Observable<any> | Promise<any> | any {
        return new Observable<boolean>((observable) => {
            this.userService.getProfile().subscribe(
                response => {
                    this.profile = response;
                    if (this.profile.additional_information) {
                        const profileAdditionalInfo = JSON.parse(this.profile.additional_information);
                        if (profileAdditionalInfo) {
                            if (profileAdditionalInfo.coaching_experience) {
                                this.profile.coaching_experience = profileAdditionalInfo.coaching_experience;
                            }
                            if (profileAdditionalInfo.coaching_level) {
                                this.profile.coaching_level = profileAdditionalInfo.coaching_level;
                            }
                        }
                    }
                    observable.next(true);
                    observable.complete();
                },
                error => {
                    observable.error(error);
                }
            )
        })
    }
}