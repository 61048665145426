import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-website-theme',
  templateUrl: './website-theme.component.html',
  styleUrls: [
    './website-theme.component.scss',
    './css/main.css',
    './css/custom-style-blocks.css',
    './css/imports.css'
  ]
})
export class WebsiteThemeComponent implements OnInit {

  @Input() logo: string;

  appLogo = environment.app.shop_logo;
  style: any = 'ast-builder-menu-mobile-hide';
  toggle: any = '';

  ngOnInit(): void {
    if (this.logo && this.logo.includes('allen')) {
      this.appLogo = environment.app.shop_logo_allen;
    }
  }

  action() {
    if (this.style == 'ast-builder-menu-mobile-show') {
      this.style = 'ast-builder-menu-mobile-hide';
      this.toggle = '';
    }
    else {
      this.style = 'ast-builder-menu-mobile-show';
      this.toggle = 'toggled';
    }
  }

}
